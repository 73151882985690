const storageConstants = {
  loggedUserData: "loggedUserData",
  FacebookData: "facebookData",
  GoogleData: "googleData",
  AppleData: "appleData",
  hideCookiePolicy: "hideCookiePolicy",
  temporaryReservationDataForRegister: "temporaryReservationDataForRegister",
  URLAfterLogin: "URLAfterLogin",
  MS_WEB_RESTAURANT_CLIENT_PORTAL_LANG :"MS_WEB_RESTAURANT_CLIENT_PORTAL_LANG",
  CityKey: "CityKey"
};


export default storageConstants;
