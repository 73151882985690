import React, { useEffect } from "react";
import { Translation } from "react-i18next";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import Logo from "../../images/logo.png";
import routeService from "../../services/routeService";
import { connect } from "react-redux";
import { configFetched } from "../../actions";
import queryString from "query-string";

import "./Footer.scss";
import i18n from "i18next";
import { storageConstants } from "../../constants";

const Footer = (props) => {
  const loggedUserDataLS = JSON.parse(
    localStorage.getItem(storageConstants.loggedUserData) || "{}"
  );
  const configurationData = props.config;
  const isIndexPage =
    !props.location.pathname ||
    props.location.pathname === "/" ||
    props.location.pathname === "/en";
  const currentLanguage = i18n.language
  const googlePlayURL = configurationData && configurationData["googlePlayUrl"];
  const appStoreURL = configurationData && configurationData["appStoreUrl"];
  const { search } = useLocation();
  const values = queryString.parse(search);

  useEffect(() => {
    if (values.plugin) {
      const elem = document.getElementsByTagName("body")[0];
      elem && elem.classList.add("plugin-mode");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //todo! remove if unnecessary

  return (
    <div className="footer">
      {!values.plugin && (
        <div className="additionalFooterLogoContainer">
          <img src={Logo} className="footerLogo logo " alt="logo" />
          <div className="downloadLinks">
            <a
              href={googlePlayURL}
              target="_blank"
              className="googlePlay"
              rel="noopener noreferrer"
            >
              &nbsp;
            </a>
            <a
              href={appStoreURL}
              target="_blank"
              className="appStore"
              rel="noopener noreferrer"
            >
              &nbsp;
            </a>
          </div>
        </div>
      )}
      <div
        className={
          !values.plugin
            ? "footer__container"
            : "footer__container footer__container--plugin"
        }
      >
        {!values.plugin ? (
          <>
            <div className="footerItem footerItem--logo">
              {isIndexPage && (
                <img src={Logo} className="footerLogo logo" alt="Logo" />
              )}
              {!isIndexPage && (
                <NavLink
                  to={routeService.getRoute("", currentLanguage)}
                  activeClassName="active"
                >
                  <img src={Logo} className="footerLogo" alt="Logo" />
                </NavLink>
              )}
            </div>

            <div className="footerItem footerItem--footerMenu">
              <ul className="footerMenu">
                <li className="footerMenu__item">
                  <p className="headerItem">
                    <Translation>
                      {(t) => t("component.footer.menu.header.forClients")}
                    </Translation>
                  </p>
                  <ul className="submenuItems">
                    <li className="submenuItems__item">
                      <Translation>
                        {(t) => (
                          <NavLink
                            to={routeService.getRoute(
                              "FOR_RESTAURANTS",
                              currentLanguage
                            )}
                            activeClassName="active"
                          >
                            {t("component.footer.menu.forRestaurants")}
                          </NavLink>
                        )}
                      </Translation>
                    </li>
                    {/* <li className="submenuItems__item">
                  <Translation>
                    {t => (
                      <NavLink
                        to={routeService.getRoute(
                          "FOR_GUESTS",
                          currentLanguage
                        )}
                        activeClassName="active"
                      >
                        {t("component.footer.menu.forGuest")}
                      </NavLink>
                    )}
                  </Translation>
                </li> */}
                    <li className="submenuItems__item">
                      <Translation>
                        {(t) => (
                          <NavLink
                            to={routeService.getRoute(
                              "HOW_IT_WORKS",
                              currentLanguage
                            )}
                            activeClassName="active"
                          >
                            {t("component.footer.menu.howItWorks")}
                          </NavLink>
                        )}
                      </Translation>
                    </li>
                    <li className="submenuItems__item">
                      <NavLink
                        to={routeService.getRoute("ABOUT", currentLanguage)}
                        activeClassName="active"
                      >
                        <Translation>
                          {(t) => t("component.footer.menu.about")}
                        </Translation>
                      </NavLink>
                    </li>
                    {/* <li className="submenuItems__item">
                  <Translation>
                    {t => (
                      <NavLink
                        to={routeService.getRoute(
                          "PRICE_LIST",
                          currentLanguage
                        )}
                        activeClassName="active"
                      >
                        {t("component.footer.menu.priceList")}
                      </NavLink>
                    )}
                  </Translation>
                </li> */}
                  </ul>
                </li>
                <li className="footerMenu__item">
                  <p className="headerItem">
                    <Translation>
                      {(t) => t("component.footer.menu.header.informations")}
                    </Translation>
                  </p>
                  <ul className="submenuItems">
                    {/* <li className="submenuItems__item">
                  <NavLink
                    to={routeService.getRoute("CONTACT", currentLanguage)}
                    activeClassName="active"
                  >
                    <Translation>
                      {t => t("component.footer.menu.contact")}
                    </Translation>
                  </NavLink>
                </li> */}
                    <li className="submenuItems__item">
                      <NavLink
                        to={routeService.getRoute("DOCUMENTS", currentLanguage)}
                        activeClassName="active"
                      >
                        <Translation>
                          {(t) => t("component.footer.menu.documents")}
                        </Translation>
                      </NavLink>
                    </li>

                    <li className="submenuItems__item">
                      <NavLink
                        to={routeService.getRoute(
                          "PRIVACY_POLICY",
                          currentLanguage
                        )}
                        activeClassName="active"
                      >
                        <Translation>
                          {(t) => t("component.footer.menu.privacyPolicy")}
                        </Translation>
                      </NavLink>
                    </li>
                    <li className="submenuItems__item">
                      <NavLink
                        to={routeService.getRoute(
                          "COOKIES_POLICY",
                          currentLanguage
                        )}
                        activeClassName="active"
                      >
                        <Translation>
                          {(t) => t("component.footer.menu.cookiesPolicy")}
                        </Translation>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="footerMenu__item">
                  <p className="headerItem">
                    <Translation>
                      {(t) => t("component.footer.menu.header.yourAccount")}
                    </Translation>
                  </p>
                  <ul className="submenuItems">
                    {loggedUserDataLS && loggedUserDataLS.firstName && (
                      <React.Fragment>
                        <li className="submenuItems__item">
                          <NavLink
                            to={routeService.getRoute(
                              "RESERVATION_HISTORY",
                              currentLanguage
                            )}
                            activeClassName="active"
                          >
                            <Translation>
                              {(t) =>
                                t("component.footer.menu.reservationHistory")
                              }
                            </Translation>
                          </NavLink>
                        </li>
                        <li className="submenuItems__item">
                          <NavLink
                            to={routeService.getRoute(
                              "YOUR_ACCOUNT",
                              currentLanguage
                            )}
                            activeClassName="active"
                          >
                            <Translation>
                              {(t) => t("component.footer.menu.yourAccount")}
                            </Translation>
                          </NavLink>
                        </li>
                        <li className="submenuItems__item">
                          <NavLink
                            to={routeService.getRoute(
                              "LOGOUT",
                              currentLanguage
                            )}
                            activeClassName="active"
                          >
                            <Translation>
                              {(t) => t("component.footer.menu.logout")}
                            </Translation>
                          </NavLink>
                        </li>
                      </React.Fragment>
                    )}

                    {!(loggedUserDataLS && loggedUserDataLS.firstName) && (
                      <React.Fragment>
                        <li className="submenuItems__item">
                          <NavLink
                            to={routeService.getRoute("LOGIN", currentLanguage)}
                            activeClassName="active"
                          >
                            <Translation>
                              {(t) => t("component.footer.menu.login")}
                            </Translation>
                          </NavLink>
                        </li>
                        <li className="submenuItems__item">
                          <NavLink
                            to={routeService.getRoute(
                              "REGISTRATION",
                              currentLanguage
                            )}
                            activeClassName="active"
                          >
                            <Translation>
                              {(t) => t("component.footer.menu.registration")}
                            </Translation>
                          </NavLink>
                        </li>
                      </React.Fragment>
                    )}
                  </ul>
                </li>
                <li className="footerMenu__item">
                  <p className="headerItem">
                    <Translation>
                      {(t) => t("component.footer.menu.header.socialMedia")}
                    </Translation>
                  </p>
                  <ul className="submenuItems">
                    <li className="submenuItems__item">
                      <a
                        href="https://www.facebook.com/mojstolikpl/"
                        target="_blank"
                        title=""
                        rel="noopener noreferrer"
                      >
                        Facebook
                      </a>
                    </li>
                    <li className="submenuItems__item">
                      <a
                        href="https://www.linkedin.com/company/mojstolik-pl"
                        target="_blank"
                        title=""
                        rel="noopener noreferrer"
                      >
                        Linkedin
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="footerMenu__item">
                  <p className="headerItem">
                    <Translation>
                      {(t) =>
                        t("component.footer.menu.header.applicationDownload")
                      }
                    </Translation>
                  </p>
                  <a
                    href={googlePlayURL}
                    target="_blank"
                    className="googlePlay"
                    rel="noopener noreferrer"
                  >
                    &nbsp;
                  </a>
                  <a
                    href={appStoreURL}
                    target="_blank"
                    className="appStore"
                    rel="noopener noreferrer"
                  >
                    &nbsp;
                  </a>
                </li>
              </ul>
            </div>
          </>
        ) : (
          <div className="footerContent--plugin">
            <div>
              <h3>POWERED BY</h3>
            </div>
            <a href="/" target="_blank">
              <img src={Logo} alt="MojStolik.pl" className="logo" />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
};
const mapDispatchToProps = { configFetched };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
